"use client"

import { cn } from "@daybridge/cn"
import { AnimatePresence, Variants, motion } from "framer-motion"
import { useLocale, useTranslations } from "next-intl"
import { Button, Icon, useToast } from "@daybridge/components"
import { signIn } from "next-auth/react"
import { useEffect, useRef, useState } from "react"
import { Formik } from "formik"
import { object, string } from "yup"
import { ThemeIllustration } from "../../(boundary)/signup/_illustrations/04_theme"
import { useQueryParameter } from "../../../../lib/useQueryParameter"
import {
  localeToEmojiFlag,
  localeToLanguageName,
} from "../../../../config/locales"
import { LanguagePicker } from "../../../../menus/languages/LanguagePicker"
import { Link } from "../../../../components/link/Link"
import { Input } from "../../../../components/input/Input"
import { IconId } from "../../../../data/_gen/types"

export default function AuthPage() {
  const t = useTranslations("auth")
  const tLegal = useTranslations("legal")
  const callbackUrl = useQueryParameter("callbackUrl")
  const locale = useLocale()

  const containerVariants: Variants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.15,
        delayChildren: 1,
      },
    },
    exit: {
      opacity: 0,
      y: -50,
      transition: { duration: 0.2 },
    },
  }

  // Variants for children
  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", damping: 20, stiffness: 300 },
    },
    exit: {
      opacity: 0,
      y: -50,
      transition: { type: "spring", damping: 20, stiffness: 300 },
    },
  }

  const [signInWithEmail, setSignInWithEmail] = useState(false)

  const errorToastSent = useRef(false)
  const emailToastSent = useRef(false)
  const toast = useToast()
  const error = useQueryParameter("error")
  const email = useQueryParameter("emailSent")
  useEffect(() => {
    if (error && !errorToastSent.current) {
      toast.error({
        title: t("sign_in_error"),
        message: t("sign_in_error_message"),
        icon: "Warning",
      })
      errorToastSent.current = true
    }

    if (email && !emailToastSent.current) {
      toast.success({
        title: t("email_sent"),
        message: t("email_sent_description"),
        icon: "Envelope",
        duration: 10000,
      })
      emailToastSent.current = true
    }
  }, [toast, t, error, errorToastSent, email, emailToastSent])

  const [loginPressed, setLoginPressed] = useState(false)

  return (
    <div
      className={cn(
        "w-full h-full",
        "font-brand",
        "relative z-0",
        "flex flex-col justify-start items-center",
        "flex-shrink-0",
        "text-md",
      )}
    >
      <nav
        className={cn(
          "w-full",
          "h-16",
          "px-4",
          "fixed z-50 top-0",
          "backdrop-blur-sm",
          "border-b border-tint",
          "flex items-center",
        )}
      >
        <div className="flex-1">
          <LanguagePicker align="start">
            <Button variant="translucent" chevron className="h-9">
              <span className="mr-2">{localeToEmojiFlag(locale)}</span>{" "}
              {localeToLanguageName(locale)}
            </Button>
          </LanguagePicker>
        </div>
        <Icon name="Daybridge" className="w-9 text-tint-heavy" />
        <div className="flex-1 flex justify-end space-x-4"></div>
      </nav>
      <div
        className={cn(
          "w-full",
          "h-[clamp(300px,40vh,550px)]",
          "bg-background",
          "overflow-hidden",
          "flex items-center justify-center",
          "relative",
          "flex-shrink-0",
        )}
        style={{
          backgroundImage: `url("/images/noise.png")`,
          backgroundSize: "25%",
          backgroundRepeat: "repeat",
        }}
      >
        <AnimatePresence mode="wait">
          {!loginPressed && <ThemeIllustration />}
        </AnimatePresence>
        <div className="pointer-events-none absolute right-0 left-0 bottom-0 h-40 bg-gradient-to-t from-surface" />
      </div>
      <div
        className={cn(
          "w-full",
          "flex-1 flex-shrink-0",
          "bg-surface",
          "flex flex-col items-center",
          "relative z-10",
        )}
      >
        <AnimatePresence mode="wait">
          <motion.div
            className={cn(
              "w-full max-w-2xl",
              "p-12",
              "flex flex-col items-center",
              "-mt-24",
            )}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.h1
              variants={itemVariants}
              className={cn(
                "mb-2",
                "text-3xl font-semibold",
                "bg-gradient-to-b from-adaptive-solid-object-highlight to-adaptive-solid-object",
                "bg-clip-text",
                "text-transparent",
                "leading-relaxed",
              )}
            >
              {t("welcome_to_daybridge")}
            </motion.h1>
            <motion.p
              variants={itemVariants}
              className={cn(
                "text-medium-contrast",
                "text-lg",
                "text-balance",
                "leading-normal",
                "text-center",
                "mb-8",
              )}
            >
              {t("choose_signin_provider")}
            </motion.p>
            <motion.div
              variants={itemVariants}
              className="w-full flex justify-center"
            >
              <AnimatePresence mode="popLayout">
                {signInWithEmail ? (
                  <motion.div
                    key="email"
                    variants={itemVariants}
                    className="w-full max-w-96"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <Formik
                      initialValues={{ email: "" }}
                      onSubmit={(values) =>
                        signIn(
                          "email",
                          {
                            callbackUrl,
                          },
                          {
                            email: values.email,
                          },
                        )
                      }
                      validationSchema={object({
                        email: string().email().required(),
                      })}
                    >
                      {(formik) => (
                        <>
                          <Input
                            type="email"
                            placeholder={t("email_address")}
                            required
                            className="text-lg w-full max-w-none mb-4"
                            {...formik.getFieldProps("email")}
                            {...formik.getFieldMeta("email")}
                            maxLength={256}
                          />
                          <Button
                            variant="solid"
                            theme="brand-blue"
                            onClick={() => {
                              void formik.submitForm()
                              setLoginPressed(true)
                            }}
                            className="w-full text-lg justify-center py-4 font-sans mb-2"
                            icon="Envelope"
                            disabled={
                              !formik.values.email ||
                              !formik.isValid ||
                              formik.isSubmitting
                            }
                          >
                            {t("sign_in")}
                          </Button>
                          <Button
                            variant="translucent"
                            onClick={() => {
                              setSignInWithEmail(false)
                            }}
                            className="w-full text-lg justify-center py-4 font-sans"
                            icon={IconId.ArrowLeft}
                          >
                            {t("cancel")}
                          </Button>
                        </>
                      )}
                    </Formik>
                  </motion.div>
                ) : (
                  <motion.div
                    key="providers"
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="w-full max-w-96 space-y-3"
                  >
                    <Button
                      variant="solid"
                      theme="brand-blue"
                      onClick={() => {
                        void signIn("google", { callbackUrl })
                        setLoginPressed(true)
                      }}
                      className="w-full text-lg justify-center py-4 font-sans"
                      icon="Google"
                    >
                      {t("sign_in_with_google")}
                    </Button>
                    <Button
                      variant="solid"
                      theme="brand-blue"
                      onClick={() => {
                        void signIn("apple", { callbackUrl })
                        setLoginPressed(true)
                      }}
                      className="w-full text-lg justify-center py-4 font-sans"
                      icon="Apple"
                    >
                      {t("sign_in_with_apple")}
                    </Button>
                    <Button
                      variant="solid"
                      theme="brand-blue"
                      onClick={() => {
                        setSignInWithEmail(true)
                      }}
                      className="w-full text-lg justify-center py-4 font-sans"
                      icon="Envelope"
                    >
                      {t("sign_in_with_email")}
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
            <motion.div
              variants={itemVariants}
              className="pt-8 flex justify-center flex-row space-x-4 font-sans"
            >
              <Link
                className="pointer-events-auto !text-medium-contrast text-base"
                target="_blank"
                href="https://daybridge.com/legal/terms"
              >
                {tLegal("terms_and_conditions")}
              </Link>
              <Link
                className="pointer-events-auto !text-medium-contrast text-base"
                target="_blank"
                href="https://daybridge.com/legal/privacy-policy"
              >
                {tLegal("privacy_policy")}
              </Link>
              <Link
                className="pointer-events-auto !text-medium-contrast text-base"
                target="_blank"
                href="https://daybridge.com/legal/cookie-policy"
              >
                {tLegal("cookie_policy")}
              </Link>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}
